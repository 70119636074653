import capitalize from 'lodash/capitalize'

import moment, { localMoment } from 'shared/lib/moment'
import { availableDateFormats } from 'shared/constants/dates'
import 'moment-duration-format'
import SettingUtils from './settings'
import accounting from 'accounting'

export function browserDateFormat () {
  const date = new Date(2013, 11, 31)

  let dateFormat = date.toLocaleDateString()

  dateFormat = dateFormat.replace('31', 'DD')
  dateFormat = dateFormat.replace('12', 'MM')
  dateFormat = dateFormat.replace('2013', 'YYYY')
  dateFormat = dateFormat.split('/').join('-')
  dateFormat = dateFormat.split('.').join('-')

  if (availableDateFormats[dateFormat]) {
    return dateFormat
  } else {
    return 'DD-MM-YYYY'
  }
}

export function dateFormat (format) {
  const dateFormat = SettingUtils.get('dates.format', 'L')
  const useAMOrPM = SettingUtils.get('dates.use_am_pm')

  let timeFormat = 'HH:mm'

  if (useAMOrPM) timeFormat = 'hh:mm A'

  const dateFormats = {
    date: dateFormat,
    dateTime: `${dateFormat} ${timeFormat}`,
    timestamp: 'YYYY-MM-DD HH:mm:ss',
    shortDate: dateFormat.replace('-YYYY', '-YY').replace('YYYY-', 'YY-'),
    short: [dateFormat, timeFormat].join(' '),
    humanShort: 'ddd MMM D',
    humanDatetime: `ddd MMM D ${timeFormat}`,
    time: timeFormat,
    noYear: dateFormat.replace('-YYYY', '').replace('YYYY-', ''),
    month: 'MMMM YYYY',
    week: '[Week] W',
    day: dateFormat,
    hour: timeFormat
  }

  if (format === 'order') {
    if (!SettingUtils.get('orders.use_times', true)) {
      format = 'date'
    } else {
      format = null
    }
  }

  if (typeof format !== 'string') {
    format = dateFormats.short
  } else if (dateFormats[format]) {
    format = dateFormats[format]
  }

  return format
}

// Formats date with given format.
export function date (date, format) {
  if (!date) return

  if (!date._isAMomentObject) {
    date = moment(date)
  }

  // Format it!
  // Allow custom format to be passed
  if (!dateFormat(format)) {
    return moment(date).format(format)
  }

  return moment(date).format(dateFormat(format))
}

// Formats order prefix
export function prefixFormat (string, values) {
  if (!string || string.length === 0) return ''

  // These are an example values, should be replaced when document will be refactored
  if (!values) {
    const date = new Date()

    values = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      customer_number: 18921,
      order_number: 23541
    }
  }

  for (const key of Object.keys(values)) {
    const re = new RegExp(`{{${key}}}`, 'gi')

    if (string.includes(`{{${key}}}`)) {
      string = string.replace(re, (matched) => values[matched.slice(2, matched.length - 2)])
    }
  }

  return string
    .split('-')
    .filter((t) => t !== '')
    .join('-')
}

// Formats date locally, without changing the timezone.
export function localDate (date, format) {
  if (!date) return

  // Format it
  return localMoment(date).format(dateFormat(format))
}

export function duration (seconds, withMinutePrecision = false) {
  const format = withMinutePrecision ? 'd [days] H [hours] m [minutes]' : 'd [days] H [hours]'

  return moment.duration(parseInt(seconds), 'seconds').format(format)
}

// Formats money.
export function money (amountInCents, options = {}) {
  const value = parseFloat(amountInCents) / 100

  return accounting.formatMoney(value, options)
}

function _precision (number) {
  number = parseFloat(number)

  const decimals = (number + '').split('.')[1]

  return decimals?.length || 0
}

export function number (number) {
  number = parseFloat(number)

  return accounting.formatNumber(number, _precision(number))
}

// Parses number from string.
//   @example
//     Utils.Format.parseNumber("8.5") # => 8.5
//     Utils.Format.parseNumber("8,5") # => 8.5
//
export function parseNumber (numberString) {
  return accounting.unformat(numberString)
}

export function parsePriceToCents (priceString) {
  let price = priceString

  if (typeof priceString === 'string') {
    price = parseNumber(priceString)
  }

  return Math.round(parseNumber(price) * 100)
}

export function parameterize (value) {
  return value?.toLowerCase()
    .replaceAll(' ', '-')
    .replaceAll(/[^a-zA-Z0-9\-_]+/g, '')
}

export const defaultCurrencyObject = {
  name: 'USD',
  symbol: '$',
  decimal: '.',
  thousand: ',',
  precision: 2,
  format: '%s%v'
}

export const formatAddress = (address) => {
  const fields = [
    address.address1,
    address.address2,
    address.city,
    address.zipcode,
    address.region,
    address.state,
    address.country
  ]

  return fields.filter(Boolean).join(', ')
}

// Converts an app template name to a humane readable name
//   @example
//     formatTemplateName('app_template_name') # => 'App Template Name'
export const formatTemplateName = (templateName) => {
  return templateName
    .split('_')
    .map((word) => capitalize(word))
    .join(' ')
}

export default {
  dateFormat,
  date,
  duration,
  localDate,
  address: formatAddress,
  money,
  number,
  parseNumber,
  parsePriceToCents,
  prefixFormat,
  defaultCurrencyObject
}
